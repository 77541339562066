import * as dataPointFormConstants from '../../redux/constants/dataPoint.form.constants';

export const ENABLED_FIELD = {
  _key: dataPointFormConstants.ENABLED_FIELD,
  type: 'boolean',
};

export const UUID_FIELD = {
  _key: dataPointFormConstants.UUID_FIELD,
  type: 'string',
  hidden: true,
};

export const LABEL_FIELD = {
  _key: dataPointFormConstants.LABEL_FIELD,
  type: 'string',
};

export const UNIT_FIELD = {
  _key: dataPointFormConstants.UNIT_FIELD,
  type: 'string',
};

export const SCALING_FACTOR_FIELD = {
  _key: dataPointFormConstants.SCALING_FACTOR_FIELD,
  default: 1,
  type: 'number',
};

export const SCALING_OFFSET_FIELD = {
  _key: dataPointFormConstants.SCALING_OFFSET_FIELD,
  type: 'number',
};

export const DATA_POINT_COMMON_FIELDS = [
  ENABLED_FIELD,
  UUID_FIELD,
  LABEL_FIELD,
  UNIT_FIELD,
  SCALING_FACTOR_FIELD,
  SCALING_OFFSET_FIELD,
].map((item) => ({
  ...item,
  _isCommon: true,
}));

/**
 * A list of fields that should be present in every instance of the array form
 */
export const DATA_POINT_ARRAY_COMMON_FIELDS = [LABEL_FIELD];

/**
 * A list of fields that should be present in every instance of an array index form
 */
export const DATA_POINT_INDEX_COMMON_FIELDS = [
  ENABLED_FIELD,
  { ...LABEL_FIELD, readOnly: true },
  UNIT_FIELD,
  SCALING_FACTOR_FIELD,
  SCALING_OFFSET_FIELD,
];

export const dataPointCommonSchema = {
  type: 'object',
  properties: {
    [ENABLED_FIELD._key]: { type: 'boolean' },
    [UUID_FIELD._key]: { type: 'string' },
    [LABEL_FIELD._key]: { type: 'string' },
    [UNIT_FIELD._key]: { type: 'string' },
    [SCALING_FACTOR_FIELD._key]: { type: 'number' },
    [SCALING_OFFSET_FIELD._key]: { type: 'number' },
  },
  required: [
    ENABLED_FIELD._key,
    LABEL_FIELD._key,
    SCALING_FACTOR_FIELD._key,
    SCALING_OFFSET_FIELD._key,
  ],
};

export const dataPointArrayCommonSchema = {
  type: 'object',
  properties: {
    [LABEL_FIELD._key]: { type: 'string' },
  },
  required: [LABEL_FIELD._key],
};

export const dataPointIndexCommonSchema = {
  type: 'object',
  properties: {
    [ENABLED_FIELD._key]: { type: 'boolean' },
    [LABEL_FIELD._key]: { type: 'string' },
    [UNIT_FIELD._key]: { type: 'string' },
    [SCALING_FACTOR_FIELD._key]: { type: 'number' },
    [SCALING_OFFSET_FIELD._key]: { type: 'number' },
  },
  required: [
    ENABLED_FIELD._key,
    LABEL_FIELD._key,
    SCALING_FACTOR_FIELD._key,
    SCALING_OFFSET_FIELD._key,
  ],
};
