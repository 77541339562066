import { useState, useEffect } from 'react';

interface DownloadFileProps {
  readonly apiDefinition: () => Promise<Response & { error?: string }>;
  readonly preDownloading: () => void;
  readonly postDownloading: () => void;
  readonly onError: (msg: string) => void;
  readonly getFileName: () => string;
}

interface DownloadedFileInfo {
  readonly download: () => Promise<void>;
}

export const useDownloadFile = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
  const [url, setFileUrl] = useState<string>('');

  const download = async () => {
    preDownloading();
    try {
      const res = await apiDefinition();
      if (res.status === 200) {
        const fileContentBlob = await res.blob();
        const createdObjUrl = URL.createObjectURL(new Blob([fileContentBlob]));
        const anchor = document.createElement('a');
        anchor.href = createdObjUrl;
        anchor.download = getFileName();
        anchor.click();
        setFileUrl(createdObjUrl);
        postDownloading();
      } else {
        const errorMessage = res.error ?? 'Error downloading File';
        onError(errorMessage);
      }
    } catch (error) {
      console.error('Error downloading File:', error);
      onError(`Error downloading File - ${error}`);
    }
  };

  useEffect(() => {
    const cleanup = () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
    return cleanup;
  }, [url]);

  return { download };
};
