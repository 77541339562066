import { createAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../core/api';
import { createTempActivityBeforeCreate } from '../../core/common/maintenance.stream';
import { enforceMinimalDuration } from './helper';
import { Todo } from '../../../../common/types/common';
import { DestinationColumn } from '../constants/column.type.constants';

type CreateActionAttr = {
  eventId: string;
  columnDestination: DestinationColumn;
  position: number;
};

type ActionSagaParam = {
  payload: CreateActionAttr;
};

export const createActivity = createAction(
  'create activity',
  (eventId: string, columnDestination: DestinationColumn, position: number) => ({
    payload: { eventId, columnDestination, position },
  }),
);

export const createActivitySuccess = createAction('create activity success', (activity: Todo) => ({
  payload: { activity },
}));

export const createActivityFail = createAction(
  'create activity fail',
  (eventId: string, error: Error) => ({
    payload: {
      eventId,
      error,
    },
  }),
);

export const reducer = {
  [createActivity.type]: (state, { eventId, columnDestination, position }: CreateActionAttr) => {
    const tempActivity = createTempActivityBeforeCreate(
      state.maintenance.events,
      eventId,
      columnDestination,
      position,
    );

    const newActivities = [...state.maintenance.activities, tempActivity];

    return {
      ...state,
      maintenance: {
        ...state.maintenance,
        activities: newActivities,
        error: '',
        movedEvents: {
          ...state.maintenance.movedEvents,
          [eventId]: true,
        },
      },
    };
  },
  [createActivitySuccess.type]: (state, { activity }) => {
    const activitiesWithoutTemp = state.maintenance.activities.filter(
      (activityItem) => activityItem.id !== activity.events[0].id,
    );
    const newEvents = state.maintenance.events.filter(
      (eventItem) => eventItem.id !== activity.events[0].id,
    );

    const newActivities = [...activitiesWithoutTemp, activity];

    return {
      ...state,
      maintenance: {
        ...state.maintenance,
        activities: newActivities,
        events: newEvents,
        error: '',
        movedEvents: {
          ...state.maintenance.movedEvents,
          [activity.events[0].id]: false,
        },
        counts: {
          ...state.maintenance.counts,
          events: state.maintenance.counts.events - 1,
        },
      },
    };
  },
  [createActivityFail.type]: (state, { eventId, error }: { eventId: string; error: Error }) => {
    const activitiesWithoutTemp = state.maintenance.activities.filter(
      (activityItem) => activityItem.id !== eventId,
    );

    return {
      ...state,
      maintenance: {
        ...state.maintenance,
        activities: activitiesWithoutTemp,
        error,
        movedEvents: {
          ...state.maintenance.movedEvents,
          [eventId]: false,
        },
      },
    };
  },
};

/**
 * Saga to handle the creation of an activity.
 *
 * This saga manages asynchronous API calls to create a new activity based on given parameters
 *
 * @param {ActionSagaParam} param0 - An object containing the payload.
 * @param {string} param0.payload.eventId - Identifier for the event.
 * @param {DestinationColumn} param0.payload.columnDestination - Describes the intended destination column for the activity.
 * @param {number} param0.payload.position - Numeric position indicating the new position of the activity in its column.
 *
 * Actions Dispatched:
 * - On successful creation: dispatches a `createActivitySuccess` which should update the global state with the new activity.
 * - On failure: dispatches a `createActivityFail` with error details, allowing error handling mechanisms to react appropriately.
 */

export function* createActivitySaga({
  payload: { eventId, columnDestination, position },
}: ActionSagaParam) {
  const startTime = Date.now();

  // Make the API call to post new activity details.
  const { response, error } = yield call(
    api.activities.postActivity,
    eventId,
    columnDestination,
    position,
  );

  yield* enforceMinimalDuration(startTime);

  if (response) {
    yield put(createActivitySuccess(response));
  } else {
    yield put(createActivityFail(eventId, error));
  }
}

export function* watchCreateActivity() {
  yield takeEvery(createActivity, createActivitySaga);
}
