import { connect } from 'react-redux';

import { getLink, getDashboardLink, getBatchesLink } from '../../index/routes';
import Menu from './Menu';
import PermissionedComponent from '../../common/permissions/PermissionedComponent.container';
import { REPORTS_PERMISSIONS, TOOLS_PERMISSIONS, EXPERIMENTS_PERMISSIONS } from '../../../../../common/constants/permissions';

const dashboardLink = getDashboardLink();
const maintenanceLink = getLink('maintenance');
const inventoryLink = getLink('inventory');
const toolsLink = getLink('tools');
const reportsLink = getLink('reports');
const productsLink = getBatchesLink();
const experimentsLink = getLink('experiments');

export const mapStateToProps = (state, { permissions }) => ({
  dashboardLink,
  maintenanceLink,
  inventoryLink,
  toolsLink,
  reportsLink,
  productsLink,
  experimentsLink,
  isToolsItemAvailable: permissions[TOOLS_PERMISSIONS].canViewTools(),
  isReportsItemAvailable: permissions[REPORTS_PERMISSIONS].availableToAll(),
  isExperimentsItemAvailable: permissions[EXPERIMENTS_PERMISSIONS].canViewExperiments()
    || permissions[EXPERIMENTS_PERMISSIONS].canViewMeasurements(),
});

export default PermissionedComponent([REPORTS_PERMISSIONS, TOOLS_PERMISSIONS, EXPERIMENTS_PERMISSIONS])(connect(mapStateToProps)(Menu));
