import { createAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { find } from 'lodash';

import * as api from '../../core/api';
import {
  getDataPointByIdAndDeviceId,
  getDataPointFromListById,
  getDataPointsOfDevice,
  getDatatron,
  getDeviceById,
} from '../selectors/datatron.selector';
import {
  compareById,
  mergeObjectInList,
  replaceObjectInList,
} from '../../../../common/helpers/object';
import { highlightDataPoint } from './datatron.devices.dataPoints.highlight.module';
import { initializeDataPoint } from '../../core/common/dataPoint';
import { handleApiError } from '../../core/common/errorHandling';
import { closeModal } from './modals.module';
import { DP_EDIT_DATA_POINT } from '../constants/modals.constants';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';
import * as notification from '../../core/notifications';
import { createConfigKeyHashToFieldsMap } from '../../core/common/datatron.deviceType';
import { getModalPayload } from '../selectors/modals.selector';
import {
  getFormTypeFromDataPoint,
  validateDataPoint,
} from '../../core/validation/validateFieldsBaseOnSchema';

export const updateDataPoint = createAction(
  'update data point',
  (deviceId, dataPointId, newConfig) => ({ payload: { deviceId, dataPointId, newConfig } }),
);

export const updateDataPointSuccess = createAction(
  'update data point - success',
  (deviceId, dataPointId, newDataPoint, configKeyHashToFieldsMap) => ({
    payload: {
      deviceId,
      dataPointId,
      newDataPoint,
      configKeyHashToFieldsMap,
    },
  }),
);

export const updateDataPointFail = createAction(
  'update data point - fail',
  (deviceId, dataPointId, error) => ({ payload: { deviceId, dataPointId, error } }),
);

export const reducer = {
  [updateDataPoint.type]: (state, { deviceId, dataPointId }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;

    const dataPoints = getDataPointsOfDevice(device);
    const dataPoint = getDataPointFromListById(dataPoints, dataPointId);
    if (!dataPoint) return state;

    const newDataPoint = {
      ...dataPoint,
      _update: {
        loading: true,
        loaded: false,
        error: null,
      },
    };

    const newDevice = {
      ...device,
      dataPoints: {
        ...device.dataPoints,
        list: replaceObjectInList(getDataPointsOfDevice(device), newDataPoint, compareById),
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: mergeObjectInList(state.datatron.devices.list, newDevice, compareById),
        },
        newDataPoint: {
          error: null,
        },
      },
    };
  },

  [updateDataPointSuccess.type]: (
    state,
    { deviceId, dataPointId, newDataPoint, configKeyHashToFieldsMap },
  ) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;

    const deviceDataPoints = getDataPointsOfDevice(device);

    const isDataPointReplaced = dataPointId !== newDataPoint.id;
    const prevDataPoint = find(deviceDataPoints, { id: dataPointId });

    const newDevice = {
      ...device,
      configKeyHashToFieldsMap: {
        ...device.configKeyHashToFieldsMap,
        ...configKeyHashToFieldsMap,
      },
      dataPoints: {
        ...device.dataPoints,
        list: replaceObjectInList(
          deviceDataPoints,
          newDataPoint,
          (item) => item.id === dataPointId,
        ),
      },
    };

    if (isDataPointReplaced) {
      newDevice.archivedDataPoints = {
        ...device.archivedDataPoints,
        list: [prevDataPoint, ...device.archivedDataPoints.list],
      };
    }

    return {
      ...state,
      datatron: {
        ...state.datatron,
        newDataPoint: {
          error: null,
        },
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById),
        },
      },
    };
  },

  [updateDataPointFail.type]: (state, { deviceId, dataPointId, error }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;

    const dataPoints = getDataPointsOfDevice(device);
    const dataPoint = getDataPointFromListById(dataPoints, dataPointId);
    if (!dataPoint) return state;

    const newDataPoint = {
      ...dataPoint,
      _update: {
        loading: false,
        loaded: false,
        error,
      },
    };

    const newDevice = {
      ...device,
      dataPoints: {
        ...device.dataPoints,
        list: replaceObjectInList(getDataPointsOfDevice(device), newDataPoint, compareById),
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        newDataPoint: {
          error,
        },
        devices: {
          ...state.datatron.devices,
          list: mergeObjectInList(state.datatron.devices.list, newDevice, compareById),
        },
      },
    };
  },
};

/**
 * updateDataPointSaga is a saga that handles updating a data point.
 * When the saga is called it first checks if the new config is valid.
 * If it is not valid, it dispatches updateDataPointFail to set the error.
 * If it is valid, it calls the api to update the data point.
 * If the api call is successful, it dispatches updateDataPointSuccess to set the new data point.
 * If the api call fails, it dispatches a notification with the error and closes the modal.
 * @param {Object} action - The action that triggered the saga.
 * @param {number} action.payload.deviceId - The id of the device the data point belongs to.
 * @param {number} action.payload.dataPointId - The id of the data point to update.
 * @param {Object} action.payload.newConfig - The new config of the data point.
 */
export function* updateDataPointSaga({ payload: { deviceId, dataPointId, newConfig } }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);

  const { deviceType } = yield select(getModalPayload, DP_EDIT_DATA_POINT);
  const currentDataPoint = yield call(getDataPointByIdAndDeviceId, state, deviceId, dataPointId);
  const formType = getFormTypeFromDataPoint(currentDataPoint);

  const validationResult = validateDataPoint(deviceType.parsedDataPointSchema, formType, newConfig);
  if (validationResult) {
    yield put(updateDataPointFail(deviceId, dataPointId, validationResult));
    return;
  }

  const { response, error } = yield call(api.datatrons.updateDataPoint, {
    datatronId: datatron.id,
    deviceId,
    dataPointId,
    payload: newConfig,
  });

  if (response) {
    const dataPoint = initializeDataPoint(response);
    const configKeyHashToFieldsMap = yield call(
      createConfigKeyHashToFieldsMap,
      [dataPoint],
      deviceType.dataPointFields,
      {},
    );

    yield put(updateDataPointSuccess(deviceId, dataPointId, dataPoint, configKeyHashToFieldsMap));
    yield put(closeModal(DP_EDIT_DATA_POINT));
    yield put(highlightDataPoint(response.id));
  } else {
    const formattedError = handleApiError(error);
    yield put(closeModal(DP_EDIT_DATA_POINT));
    yield put(
      sendNotification(
        notificationMessages.server_error,
        {
          ...notificationMessages.something_happened,
          values: { error: JSON.stringify(formattedError) },
        },
        notification.NOTIFICATION_ERROR,
      ),
    );
  }
}

/**
 * Watches for UPDATE_DATA_POINT and calls updateDataPointSaga.
 *
 * @return {void}
 */
export function* watchUpdateDataPoint() {
  yield takeEvery(updateDataPoint, updateDataPointSaga);
}
