import { cloneDeep } from 'lodash';
import { DEFAULT_TAB } from '../constants/datatron.tabs.constants';
import { STACKED_CHART } from '../constants/chart.type.constants';
import { Todo } from '../../../../common/types/common';
import { AbridgedCommand } from '../../../../common/types/api/datatron.devices';
import { ListState } from './util';

export type State = {
  details: Todo;
  chartType: Todo;
  tab: Todo;
  newAssistant?: Todo;
  loading: boolean;
  loaded: boolean;
  error: Todo | null;
  devices: ListState<Todo>;
  deviceClasses: ListState<Todo>;
  deviceTypes: ListState<Todo>;
  openedDataPointId: string | null;
  newDataPoint: {
    deviceId: string | null;
    loaded: boolean;
    loading: boolean;
    error: Todo | null;
  };
  highlightedDataPointIds: Todo[];
  dataPointMetric: ListState<Todo> & {
    moreAvailable: false;
    dataPointId: null;
    utcMode: true;
  };
  deviceCommands: AbridgedCommand[];
  deviceCommandTotal: number;
};

export const basic: State = {
  details: {},
  chartType: STACKED_CHART,
  tab: DEFAULT_TAB,
  loading: false,
  loaded: false,
  error: null,
  devices: {
    list: [],
    loading: false,
    loaded: false,
    error: null,
  },
  deviceClasses: {
    list: [],
    loading: false,
    loaded: false,
    error: null,
  },
  deviceTypes: {
    list: [],
    loading: false,
    loaded: false,
    error: null,
  },
  openedDataPointId: null,
  newDataPoint: {
    deviceId: null,
    loaded: false,
    loading: false,
    error: null,
  },
  highlightedDataPointIds: [],
  dataPointMetric: {
    list: [],
    loaded: false,
    loading: false,
    error: null,
    moreAvailable: false,
    dataPointId: null,
    utcMode: true,
  },
  deviceCommands: [],
  deviceCommandTotal: 0,
};

export const testState: State = {
  chartType: STACKED_CHART,
  deviceCommands: [],
  deviceCommandTotal: 0,
  openedDataPointId: null,
  details: {
    id: 'ODE4MTUxMjcxODM2NzU1MA==',
    name: 'Fake Datatron #1',
    image: '/img/position.png',
    make: 'Fake Maker #2',
    model: 'Fake Model #3',
    year: 2017,
    serialNumber: '0c7b4aa0-51ba-4807-bbae-8a3dadc182e0',
    imagePath: '/path/to/file',
    macAddress: '52-9F-96-85-55-F8',
    ipAddress: '8.8.8.8',
    stats: {
      total: {
        datatronCpu: 1,
        datatronDisk: 0.9,
        datatronNetwork: 0.4,
        datatronRam: 0.2,
      },
      chart: [
        {
          startAt: '2017-12-08T05:00:00.000Z',
          period: 'hour',
          datatronCpu: [1, 1, 1, 1],
          datatronDisk: [0.8, 0.8, 0.8, 0.8],
          datatronNetwork: [0.4, 0.4, 0.4, 0.4],
          datatronRam: [0.2, 0.2, 0.2, 0.2],
        },
      ],
    },
    location: {
      id: 'NDE4MTUxMjcxODM2NzU1MA==',
      name: 'Line 418',
      type: 'line',
      path: [
        'Country 2',
        'City 5',
        'District 12',
        'Factory 25',
        'Hall 51',
        'Floor 103',
        'Assembly 208',
      ],
    },
    organization: {
      id: 'MDE4MTUxMjcxODM2NzU1MA==',
      name: 'Organization Name',
    },
    isAvailable: true,
    isMaintenancePlanned: false,
  },
  tab: DEFAULT_TAB,
  newAssistant: {
    selected: null,
  },
  loading: false,
  loaded: true,
  error: null,
  devices: {
    list: [
      {
        id: 'MTE1MjY4MzcyNzM5NjU=',
        name: 'First Siemens S7 PLC',
        configKeyHashToFieldsMap: {
          '["identifier"]': [
            {
              _key: 'identifier',
              type: 'string',
            },
          ],
        },
        deviceType: {
          id: 'NTE1MjY4MzcyNzM5NjU=',
        },
        dataPoints: {
          list: [
            {
              id: 'OTE1MjY4MzcyNzM5NjU=',
              uuid: 'field_uuid',
              label: 'THIS IS LABEL',
              config: {
                identifier: 'field_identifier_testing',
              },
              configKeys: ['identifier'],
              configKeysHash: '["identifier"]',
              _update: {
                error: null,
                loading: false,
                loaded: false,
              },
            },
          ],
          loaded: true,
          loading: false,
          error: null,
          query: 'testing',
          sort: {
            option: 'uuid',
            way: 'DESC',
          },
        },
        archivedDataPoints: {
          list: [
            {
              id: 'OTE1MjY4MzcyNzM5NjZ=',
              uuid: 'field_uuid_2',
              label: 'THIS IS LABEL 2',
              config: {
                identifier: 'field_identifier_2_testing_archive',
              },
              configKeys: ['identifier'],
              configKeysHash: '["identifier"]',
              _update: {
                error: null,
                loading: false,
                loaded: false,
              },
            },
          ],
          loaded: true,
          loading: false,
          error: null,
          query: 'testing_archive',
          sort: {
            option: 'label',
            way: 'ASC',
          },
        },
      },
    ],
    loading: false,
    loaded: true,
    error: null,
  },
  deviceClasses: {
    list: ['PLC', 'AnalogCard', 'RemoteSensor', 'Video', 'NCU'],
    loading: false,
    loaded: true,
    error: null,
  },
  deviceTypes: {
    list: [
      {
        id: 'NTE1MjY4MzcyNzM5NjU=',
        name: 'Siemens S7 PLC',
        make: 'Siemens',
        model: 'S7',
        deviceClass: 'PLC',
        hasDeviceSchema: true,
        deviceSchema: {
          id: 'schemas/siemens-s7',
          type: 'object',
          $schema: 'http://json-schema.org/draft-04/schema#',
          required: ['profinetName', 'payloadSize'],
          properties: {
            payloadSize: {
              type: 'integer',
              maximum: 1344,
              minimum: 1,
            },
            profinetName: {
              type: 'string',
            },
          },
          description: 'Schema for a Siemens S7 PLC data acquisition device',
          additionalProperties: false,
        },
        deviceFields: [
          {
            _key: 'profinetName',
            _required: true,
            _parent: [],
            type: 'string',
          },
          {
            _key: 'payloadSize',
            _required: true,
            _parent: [],
            type: 'integer',
            minimum: 1,
            maximum: 1344,
          },
        ],
        hasDataPointSchema: true,
        dataPointSchema: {
          id: 'schemas/siemens-s7',
          type: 'object',
          allOf: [
            {
              required: ['label'],
              properties: {
                label: {
                  type: 'string',
                },
                length: {
                  type: 'integer',
                  minimum: 1,
                  maximum: 1000,
                },
              },
            },
          ],
        },
        dataPointFields: [
          {
            _key: 'label',
            _required: true,
            _parent: [],
            type: 'string',
          },
          {
            _key: 'length',
            _required: true,
            _parent: [],
            type: 'integer',
            minimum: 1,
            maximum: 1000,
          },
        ],
      },
    ],
    loading: false,
    loaded: true,
    error: null,
  },
  newDataPoint: {
    deviceId: 'MTE1MjY4MzcyNzM5NjU=',
    loaded: false,
    loading: false,
    error: null,
  },
  highlightedDataPointIds: ['OTE1MjY4MzcyNzM5NjU='],
  dataPointMetric: {
    list: [],
    loaded: false,
    loading: false,
    error: null,
    moreAvailable: false,
    dataPointId: null,
    utcMode: true,
  },
};

export const initial = cloneDeep<State>(basic);
