import * as fileFormats from '../constants/fileFormats';

export const EXCEL_MIME_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const CSV_MIME_TYPE = 'text/csv';
export const TSV_MIME_TYPE = 'text/tab-separated-values';

export const exportedFileTypes = [EXCEL_MIME_TYPE, CSV_MIME_TYPE, TSV_MIME_TYPE];

export const getMimeType = (fileFormat) => {
  switch (fileFormat) {
    case fileFormats.CSV:
      return CSV_MIME_TYPE;
    case fileFormats.TSV:
      return TSV_MIME_TYPE;
    case fileFormats.XLSX:
      return EXCEL_MIME_TYPE;
    default:
      return null;
  }
};
