import { createAction } from '@reduxjs/toolkit';
import { fetchDeviceCommandHistory } from '../../core/api/datatrons';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getDatatron } from '../selectors/datatron.selector';
import { CommandsResponse } from '../../../../common/types/api/datatron.devices';
import { AppReducerMapBuilder } from './util';
import { quickCommandSuccess, quickCommandFailure } from './datatron.devices.quickCommands.module';
import { transformErrorToMessageString } from '../../../../common/utils';

export const fetchCommandHistory = createAction(
  'fetch device command history',
  (deviceId, queryParameters) => ({ payload: { deviceId, queryParameters } }),
);

export const setCommandHistory = createAction(
  'set device command history',
  ({ commands, total }: CommandsResponse) => ({ payload: { commands, total } }),
);

export const reSetCommandHistory = createAction('re-set device command history');

export const reducer = AppReducerMapBuilder.new()
  .add(fetchCommandHistory, (state, { deviceId }) => ({
    ...state,
    modals: {
      ...state.modals,
      commandState: {
        ...state.modals.commandState,
        deviceId,
        submitting: true,
      },
    },
  }))
  .add(setCommandHistory, (state, { commands, total }: CommandsResponse) => ({
    ...state,
    datatron: {
      ...state.datatron,
      deviceCommands: commands,
      deviceCommandTotal: total,
    },
  }))
  .add(reSetCommandHistory, (state) => ({
    ...state,
    datatron: {
      ...state.datatron,
      deviceCommands: [],
      deviceCommandTotal: 0,
    },
  }))

  .build();

export function* fetchCommandHistorySaga({ payload: { deviceId, queryParameters } }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);

  const queryString = new URLSearchParams(queryParameters).toString();
  const { response, error } = yield call(
    fetchDeviceCommandHistory,
    datatron.id,
    deviceId,
    queryString,
  );

  if (response) {
    yield put(setCommandHistory(response));
    yield put(quickCommandSuccess(deviceId));
  } else {
    yield put(reSetCommandHistory());
    const errorString = transformErrorToMessageString(error);
    yield put(quickCommandFailure(deviceId, errorString));
  }
}

export function* wachCommandHistory() {
  yield takeEvery(fetchCommandHistory, fetchCommandHistorySaga);
}
