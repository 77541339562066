import { createAction } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { startSubmit, stopSubmit, touch } from 'redux-form';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { compareById, replaceObjectInList } from '../../../../common/helpers/object';
import * as api from '../../core/api';
import { handleApiError } from '../../core/common/errorHandling';
import { applyFormValuesOntoConfig } from '../../core/common/dynamicField';
import notificationMessages from '../../messages/notification.message';
import * as formConstants from '../constants/datatron.device.form.constants';
import { DP_EDIT_DEVICE } from '../constants/modals.constants';
import { getDeviceFormFields } from '../selectors/datatron.devices.selector';
import { getDatatron, getDeviceById, getDeviceTypeById } from '../selectors/datatron.selector';
import { NOTIFICATION_ERROR } from '../../core/notifications';
import { getModalPayload } from '../selectors/modals.selector';
import { closeModal } from './modals.module';
import { sendNotification } from './notifications.module';
import { AppReducerMapBuilder } from './util';
import { validateDevice } from '../../core/validation/validateFieldsBaseOnSchema';
import { UpdateDatatronDeviceSchema } from '../../../../common/validation/datatrons.devices.validation';

export const updateDevice = createAction('updateDevice/submit', (deviceId: string) => ({
  payload: { deviceId },
}));

export const updateDeviceSuccess = createAction('updateDevice/success', (updatedDevice) => ({
  payload: updatedDevice,
}));

export const updateDeviceFail = createAction('updateDevice/fail', (deviceId, error) => ({
  payload: { deviceId, error },
}));

export const reducer = AppReducerMapBuilder.new()
  .add(updateDevice, (state, { deviceId }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;

    const newDevice = {
      ...device,
      _update: {
        ...device._update,
        loading: true,
        loaded: false,
        error: null,
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById),
        },
      },
    };
  })
  .add(updateDeviceSuccess, (state, updatedDevice) => {
    const device = getDeviceById(state, updatedDevice.id);
    if (!device) return state;

    const newDevice = {
      ...device,
      ...updatedDevice,
      _update: {
        ...device._update,
        isRenaming: false,
        loading: false,
        loaded: true,
        error: null,
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById),
        },
      },
    };
  })
  .add(updateDeviceFail, (state, { deviceId, error }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) return state;

    const newDevice = {
      ...device,
      _update: {
        ...device._update,
        loading: false,
        loaded: false,
        error,
      },
    };

    return {
      ...state,
      datatron: {
        ...state.datatron,
        devices: {
          ...state.datatron.devices,
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById),
        },
      },
    };
  })
  .build();

export function* touchAllFieldsSaga(additionalKeys) {
  yield put(
    touch(
      formConstants.DATATRON_DEVICE_FORM_NAME,
      ...formConstants.DATATRON_DEVICE_PROPS,
      ...additionalKeys,
    ),
  );
}

/**
 * Saga to handle the device update process in Datatron.
 * This function selects the current state, retrieves device details, and processes form data.
 * It applies form values onto the device's config, validates the form, and sends the device update request.
 * If the update succeeds, the UI is updated, and a success notification is shown.
 * If the update fails, an error notification is displayed.
 * @returns {void}
 */
export function* updateDeviceSaga() {
  const state = yield select();
  const datatron = getDatatron(state);
  const { device } = getModalPayload(state, DP_EDIT_DEVICE);
  const deviceType = getDeviceTypeById(state, device.deviceType.id);
  const additionalFields = deviceType && deviceType.hasDeviceSchema ? deviceType.deviceFields : [];
  const additionalKeys = additionalFields.map((item) => item._key);

  yield call(touchAllFieldsSaga, additionalKeys);
  const body = getDeviceFormFields(state, additionalKeys);
  if (additionalFields.length > 0) {
    body.config = applyFormValuesOntoConfig(
      device.config || {},
      pick(body, additionalKeys),
      additionalFields,
    );
  }

  yield put(startSubmit(formConstants.DATATRON_DEVICE_FORM_NAME));

  const validationResult = validateDevice(
    UpdateDatatronDeviceSchema,
    deviceType.deviceSchema,
    body,
  );
  if (validationResult) {
    yield put(stopSubmit(formConstants.DATATRON_DEVICE_FORM_NAME, validationResult));
    yield put(updateDeviceFail(device.id, validationResult));
    return;
  }

  const { response, error } = yield call(api.datatrons.updateDevice, datatron.id, device.id, body);

  if (response) {
    yield put(updateDeviceSuccess(response));
    yield put(stopSubmit(formConstants.DATATRON_DEVICE_FORM_NAME));
    yield put(closeModal(DP_EDIT_DEVICE));
    yield put(sendNotification(notificationMessages.device_updated_title));
  } else {
    const formattedError = handleApiError(error);
    yield put(stopSubmit(formConstants.DATATRON_DEVICE_FORM_NAME));
    yield put(closeModal(DP_EDIT_DEVICE));
    yield put(
      sendNotification(
        notificationMessages.server_error,
        {
          ...notificationMessages.something_happened,
          values: { error: JSON.stringify(formattedError) },
        },
        NOTIFICATION_ERROR,
      ),
    );
  }
}

/**
 * Watches for `updateDevice` action and starts `updateDeviceSaga` when it is triggered
 */
export function* watchUpdateDevice() {
  yield takeEvery(updateDevice.type, updateDeviceSaga);
}
