export const ENABLED_FIELD = 'enabled';
export const UUID_FIELD = 'uuid';
export const LABEL_FIELD = 'label';
export const UNIT_FIELD = 'unit';
export const SCALING_FACTOR_FIELD = 'scalingFactor';
export const SCALING_OFFSET_FIELD = 'scalingOffset';

export const SIMPLE_DATAPOINT_FORM_TYPE = 'simpleDatapoint';
export const ARRAY_DATAPOINT_FORM_TYPE = 'arrayDatapoint';
export const ARRAY_INDEX_DATAPOINT_FORM_TYPE = 'arrayIndexDatapoint';
