export const ROLES_PERMISSIONS = 'ROLES_PERMISSIONS';
export const ROLES_MANAGER = 'RolesManager';

export const CALENDAR_PERMISSIONS = 'CALENDAR_PERMISSIONS';
export const CALENDAR_MANAGER = 'CalendarManager';
export const CALENDAR_EDITOR = 'CalendarEditor';
export const CALENDAR_VIEWER = 'CalendarViewer';

export const USERS_PERMISSIONS = 'USER_PERMISSIONS';
export const USERS_MANAGER = 'UsersManager';

export const ACCOUNTS_PERMISSIONS = 'ACCOUNTS_PERMISSIONS';
export const ACCOUNTS_MANAGER = 'AccountsManager';
export const ACCOUNTS_VIEWER = 'AccountsViewer';

export const LOCATIONS_PERMISSIONS = 'LOCATIONS_PERMISSIONS';
export const LOCATIONS_MANAGER = 'LocationsManager';

export const ORGANIZATIONS_PERMISSIONS = 'ORGANIZATIONS_PERMISSIONS';
export const ORGANIZATIONS_MANAGER = 'OrganizationsManager';

export const MACHINES_PERMISSIONS = 'MACHINES_PERMISSIONS';
export const MACHINES_MANAGER = 'MachinesManager';

export const EVENTS_PERMISSIONS = 'EVENTS_PERMISSIONS';
export const EVENTS_MANAGER = 'EventsManager';

export const REPORTS_PERMISSIONS = 'REPORTS_PERMISSIONS';
export const REPORTS_MANAGER = 'ReportsManager';

export const PRODUCTS_PERMISSIONS = 'PRODUCTS_PERMISSIONS';
export const PRODUCTS_MANAGER = 'ProductsManager';
export const PRODUCTS_VIEWER = 'ProductsViewer';

export const DATATRONS_PERMISSIONS = 'DATATRONS_PERMISSIONS';
export const DATATRONS_MANAGER = 'DatatronsManager';

export const TOOLS_PERMISSIONS = 'TOOLS_PERMISSIONS';
export const TOOLS_MANAGER = 'ToolsManager';
export const TOOLS_VIEWER = 'ToolsViewer';

export const EXPERIMENTS_PERMISSIONS = 'EXPERIMENTS_PERMISSIONS';
export const EXPERIMENTS_MANAGER = 'ExperimentsManager';
export const EXPERIMENTS_VIEWER = 'ExperimentsViewer';

export const ALL_PERMISSIONS = [
  ROLES_PERMISSIONS,
  CALENDAR_PERMISSIONS,
  USERS_PERMISSIONS,
  ACCOUNTS_PERMISSIONS,
  LOCATIONS_PERMISSIONS,
  ORGANIZATIONS_PERMISSIONS,
  MACHINES_PERMISSIONS,
  EVENTS_PERMISSIONS,
  REPORTS_PERMISSIONS,
  PRODUCTS_PERMISSIONS,
  DATATRONS_PERMISSIONS,
  TOOLS_PERMISSIONS,
  EXPERIMENTS_PERMISSIONS,
] as const;

export type Permission = (typeof ALL_PERMISSIONS)[number];

export const allRoles = [
  ROLES_MANAGER,
  CALENDAR_MANAGER,
  CALENDAR_EDITOR,
  CALENDAR_VIEWER,
  USERS_MANAGER,
  ACCOUNTS_MANAGER,
  ACCOUNTS_VIEWER,
  LOCATIONS_MANAGER,
  ORGANIZATIONS_MANAGER,
  MACHINES_MANAGER,
  EVENTS_MANAGER,
  REPORTS_MANAGER,
  PRODUCTS_MANAGER,
  PRODUCTS_VIEWER,
  DATATRONS_MANAGER,
  TOOLS_MANAGER,
  TOOLS_VIEWER,
  EXPERIMENTS_MANAGER,
  EXPERIMENTS_VIEWER,
] as const;

export type Role = (typeof allRoles)[number];

export const isRole = (possibleRole: unknown): possibleRole is Role =>
  typeof possibleRole === 'string' && allRoles.includes(possibleRole as Role);

export const isPermission = (
  possiblePermission: unknown,
): possiblePermission is Permission =>
  typeof possiblePermission === 'string' &&
  ALL_PERMISSIONS.includes(possiblePermission as Permission);
