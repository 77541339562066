import { prioritized, inProgress } from '../../../../common/enums/activityState';

export const DND_COLUMN_ITEM_TYPE = 'columnItem';

export const COLUMN_IN_PROGRESS = inProgress;
export const COLUMN_PRIORITIZED = prioritized;
export const COLUMN_INBOX = 'inbox';

export type DestinationColumn = 'inProgress' | 'prioritized';

export type ListPosition = {
  id: string;
  state: DestinationColumn;
  position: number;
};

export type MoveActivity = (
  id: string,
  destination: DestinationColumn,
  listPosition: ListPosition[],
) => void;

export type CreateActivity = (id: string, destination: DestinationColumn, position: number) => void;
