import { isEmpty } from 'lodash';
import { ZodSchema, z } from 'zod';

interface JsonSchema {
  [key: string]: any;
}

interface SchemaMap {
  [key: string]: ZodSchema<any>;
}

export function jsonToZod(jsonSchema: JsonSchema): {
  mainSchema: ZodSchema<any>;
  schemaMap: SchemaMap;
} {
  const schemaMap: SchemaMap = {};

  function traverse(schema: JsonSchema): ZodSchema<any> {
    if (schema.hasOwnProperty('$ref')) {
      const refPath = schema.$ref.replace('#/definitions/', '');
      if (!jsonSchema.definitions || !jsonSchema.definitions[refPath]) {
        throw new Error(`Schema reference ${refPath} is not defined.`);
      }
      if (!schemaMap[refPath]) {
        schemaMap[refPath] = traverse(jsonSchema.definitions[refPath]);
      }
      return schemaMap[refPath];
    }

    if (schema.enum) {
      return z.enum(schema.enum);
    }
    if (schema.oneOf) {
      const unions = schema.oneOf.map(traverse);
      return z.union(unions);
    }

    if (schema.type === 'array') {
      return z.array(traverse(schema.items));
    }

    if (schema.type === 'integer') {
      return z.number().int();
    }

    if (schema.type === 'number') {
      return z.number();
    }

    if (schema.type === 'string') {
      return z.string();
    }
    if (schema.type === 'boolean') {
      return z.boolean();
    }
    // schema type is object
    const properties: { [key: string]: ZodSchema<any> } = {};
    if (schema.properties) {
      for (const propName in schema.properties) {
        properties[propName] = traverse(schema.properties[propName]);
      }
    }
    return z.object(properties);
  }

  const filteredOneOf = jsonSchema.oneOf.filter(
    (obj) => obj.$ref === '#/definitions/simpleDatapoint',
  );
  jsonSchema.oneOf = filteredOneOf;
  const mainSchema = traverse(jsonSchema);

  return { mainSchema, schemaMap };
}

interface JSONSchemaDefinition {
  type?: string;
  properties?: Record<string, JSONSchemaDefinition>;
  required?: string[];
  oneOf?: JSONSchemaDefinition[];
  enum?: (string | number)[];
}

function isOneOfSchema(schema: any): schema is { oneOf: JSONSchemaDefinition[] } {
  return Array.isArray((schema as any).oneOf);
}

/**
 * Given an array of schemas, create a single Zod schema that represents the union of all of them.
 * This is done by finding the common enum field that all schemas share, and using that as the
 * discriminator to switch between the different schemas.
 * @param oneOfSchema an array of schemas, each of which is a JSON schema
 * @returns a Zod schema that represents the union of all of the schemas
 */
function createZodSchemaForOneOf(
  oneOfSchema: any[],
  callCreateZodSchemaFromJSON: (jsonSchema: any) => z.ZodTypeAny,
): z.ZodTypeAny {
  const findCommonEnumField = () => {
    const allFields = oneOfSchema.flatMap((schema) => Object.keys(schema.properties));
    const uniqueFields = [...new Set(allFields)];

    return uniqueFields.find((field) =>
      oneOfSchema.every(
        (schema) =>
          schema.properties[field] &&
          schema.properties[field].enum &&
          schema.properties[field].enum.length > 0,
      ),
    );
  };
  const commonEnumField = findCommonEnumField();

  if (!commonEnumField) {
    return z.any();
  }
  const schemas: [
    z.ZodDiscriminatedUnionOption<string>,
    ...z.ZodDiscriminatedUnionOption<string>[],
  ] = oneOfSchema.map((schema) => {
    const properties = schema.properties;
    const enumValues = properties[commonEnumField].enum;
    const required = new Set(schema.required || []);

    let schemaObj: { [key: string]: z.ZodTypeAny } = {};

    for (const [key, value] of Object.entries(properties)) {
      if (key === commonEnumField) {
        // Create a union of literals for all enum values
        schemaObj[key] = z.enum(enumValues as [string, ...string[]]);
      } else {
        const zodSchema = callCreateZodSchemaFromJSON(value);
        schemaObj[key] = required.has(key) ? zodSchema : zodSchema.optional();
      }
    }

    // schemaObj[commonEnumField] = z.literal(discriminatorValue);

    return z.object(schemaObj) as z.ZodDiscriminatedUnionOption<string>;
  }) as [z.ZodDiscriminatedUnionOption<string>, ...z.ZodDiscriminatedUnionOption<string>[]];

  return z.discriminatedUnion(commonEnumField, schemas);
}

/**
 * Creates a Zod schema from a JSON schema object.
 *
 * Given a JSON schema object, creates a Zod schema that represents the same
 * constraints.
 *
 * @param jsonSchema - The JSON schema to be converted to a Zod schema.
 * @returns A Zod schema that represents the same constraints as the given JSON
 * schema.
 */

export function createBaseZodSchemaFromJSON(jsonSchema: any): z.ZodTypeAny {
  if (jsonSchema.type === 'object') {
    const shape: { [key: string]: z.ZodTypeAny } = {};
    const required = new Set(jsonSchema.required || []);

    for (const [key, value] of Object.entries(jsonSchema.properties) as [string, any][]) {
      if (isOneOfSchema(value)) {
        const fieldSchema = createZodSchemaForOneOf(value.oneOf, createBaseZodSchemaFromJSON);
        shape[key] = required.has(key) ? fieldSchema : fieldSchema.optional();
      } else if (key === 'custom' && value?.additionalProperties === true) {
        // do nothing
      } else {
        const fieldSchema = createBaseZodSchemaFromJSON(value);
        shape[key] = required.has(key) ? fieldSchema : fieldSchema.optional();
      }
    }

    return z.object(shape);
  }

  if (jsonSchema.type === 'string') {
    let schema = z.string();

    if (jsonSchema.format === 'ipv4') {
      schema = schema.ip({ version: 'v4' });
    }

    if (jsonSchema.pattern) {
      schema = schema.regex(new RegExp(jsonSchema.pattern));
    }

    return schema;
  }

  if (jsonSchema.type === 'integer' || jsonSchema.type === 'number') {
    let schema = jsonSchema.type === 'integer' ? z.number().int() : z.number();

    if (jsonSchema.minimum !== undefined) {
      schema = schema.min(jsonSchema.minimum);
    }

    if (jsonSchema.maximum !== undefined) {
      schema = schema.max(jsonSchema.maximum);
    }

    return schema;
  }

  if (jsonSchema.type === 'boolean') {
    return z.boolean();
  }

  if (jsonSchema.enum) {
    if (jsonSchema.enum.every((item: any) => typeof item === 'number')) {
      return z.number().refine((val) => jsonSchema.enum.includes(val));
    }
    return z.enum(jsonSchema.enum as [string, ...string[]]);
  }

  if (jsonSchema.type === 'array') {
    if (jsonSchema.items) {
      const itemSchema = createBaseZodSchemaFromJSON(jsonSchema.items);
      return z
        .array(itemSchema)
        .refine((arr) => arr.every((item) => itemSchema.safeParse(item).success), {
          message: `Satisfy the schema: ${JSON.stringify(jsonSchema.items)}`,
          path: [],
        });
    }
  }

  return z.any();
}

/**
 * Creates a Zod schema for a parsed JSON schema that has the `allOf` keyword.
 * This function will find the first `properties` object in the `allOf` array,
 * and use that as the base schema. If the `allOf` array contains an object with
 * a `oneOf` keyword, it will create a Zod schema for that as well, and combine
 * it with the base schema using the `and` method.
 * @param parsedSchema a parsed JSON schema object
 * @returns a Zod schema that represents the combination of all of the schemas
 * in the `allOf` array
 */
export function createZodSchemaForAllOf(parsedSchema: any): z.ZodTypeAny {
  const { allOf } = parsedSchema;
  const findAllProperties = allOf.find((item) => item.properties);
  const baseSchema = createBaseZodSchemaFromJSON({ ...findAllProperties, type: 'object' });

  const oneOfSchema = allOf.find((item) => item.oneOf);

  if (oneOfSchema) {
    const oneOfZodSchema = createZodSchemaForOneOf(oneOfSchema.oneOf, createBaseZodSchemaFromJSON);
    return baseSchema.and(oneOfZodSchema);
  }

  return baseSchema;
}

/**
 * Given a parsed JSON schema object, returns a Zod schema that represents that object.
 * This function will recursively traverse the JSON schema and create a Zod schema for each
 * of the properties. If the JSON schema has an `allOf` keyword, it will create a Zod schema
 * for each of the schemas in the array and combine them with the `and` method.
 * If the JSON schema has a `oneOf` keyword, it will create a Zod schema for each of the
 * schemas in the array and combine them with the `or` method.
 * @param jsonSchema a parsed JSON schema object
 * @returns a Zod schema that represents the JSON schema
 */
export function createZodSchemaFromJSON(jsonSchema: any): z.ZodTypeAny {
  if (jsonSchema.allOf) {
    return createZodSchemaForAllOf(jsonSchema);
  }
  if (jsonSchema.properties && jsonSchema.properties.custom && jsonSchema.properties.internal) {
    return createBaseZodSchemaFromJSON(jsonSchema.properties.custom);
  }

  if (isEmpty(jsonSchema)) {
    return z.any();
  }
  return createBaseZodSchemaFromJSON(jsonSchema);
}

export function createDataPointSchemaFromJSO(jsonSchema: any): z.ZodTypeAny {
  return createBaseZodSchemaFromJSON(jsonSchema);
}
