import React from 'react';
import PropTypes from 'prop-types';

import AdminPageRemoveLocationModal from './adminPage/removeLocation/RemoveLocationModal.container';
import AdminPageRemoveOrganizationModal from './adminPage/removeOrganization/RemoveOrganizationModal.container';
import AddDeviceModal from './datatronPage/deviceForm/AddDeviceModal.container';
import EditDeviceModal from './datatronPage/deviceForm/EditDeviceModal.container';
import ViewDataPointModal from './datatronPage/viewDataPoint/ViewDataPointModal.container';
import RemoveReportModalContainer from './reports/removeReport/RemoveReportModal.container';
import DuplicateReportModalContainer from './reports/duplicateReport/DuplicateReportModal.container';
import LegendDataPointModalContainer from './reports/editLegendItem/EditLegendItem.container';
import AddReportsTabModalContainer from './reports/addReportsTab/AddReportsTabModal.container';
import EditReportsTabModalContainer from './reports/editReportsTab/EditReportsTabModal.container';
import RemoveReportsTabModalContainer from './reports/removeReportsTab/RemoveReportsTabModal.container';
import AddReportModalContainer from './reports/reportForm/AddReportModule.container';
import EditReportModalContainer from './reports/reportForm/EditReportModule.container';
import AddToolModalContainer from './tools/addToolForm/AddToolModal.container';
import EditToolModalContainer from './tools/editToolForm/EditToolModal.container';
import ViewToolModalContainer from './tools/viewToolModal/ViewToolModal.container';
import AddExperimentModalContainer from './experimentsPage/experimentForm/AddExperimentModal.container';
import EditExperimentModalContainer from './experimentsPage/experimentForm/EditExperimentModal.container';
import ArchiveExperimentModalContainer from './experimentsPage/archiveExperiment/ArchiveExperimentModal.container';
import DownloadExperimentModalContainer from './experimentsPage/downloadExperiment/DownloadExperimentModal.container';
import DownloadLinksExperimentModal
  from './experimentsPage/downloadLinksExperiment/DownloadLinksExperimentModal.container';
import ArchiveMeasurementModalContainer from './experimentsPage/archiveMeasurement/ArchiveMeasurementModal.container';
import MergeMeasurementModalContainer from './experimentsPage/mergeMeasurement/MergeMeasurementModal.container';
import DownloadMeasurementModalContainer
  from './experimentsPage/downloadMeasurement/DownloadMeasurementModal.container';
import ConfirmationModalContainer from './confirmationModal/ConfirmationModal.container';
import SyncFailedModalContainer from './machinePage/syncFailedModal/SyncFailedModal.container';
import { DataPointForm } from './datatronPage/dataPointForm/DataPointForm';

/*
  This component used to include modal components across all applications.
*/

const Modals = ({
  isAdminPageRemoveLocationModalOpen,
  isAdminPageRemoveOrganizationModalOpen,
  isAddDeviceModalOpen,
  isEditDeviceModalOpen,
  isAddDataPointModalOpen,
  isEditDataPointModalOpen,
  isViewDataPointModalOpen,
  isReportRemoveModalOpen,
  isReportDuplicateModalOpen,
  isReportLegendDataPointModalOpen,
  isReportsTabAddModalOpen,
  isReportsTabEditModalOpen,
  isReportsTabRemoveModalOpen,
  isReportAddModalOpen,
  isReportEditModalOpen,
  isToolAddModalOpen,
  isToolEditModalOpen,
  isToolDetailsModalOpen,
  isExperimentAddModalOpen,
  isExperimentEditModalOpen,
  isExperimentArchiveModalOpen,
  isExperimentDownloadModalOpen,
  isExperimentDownloadLinksModalOpen,
  isMeasurementMergeModalOpen,
  isMeasurementArchiveModalOpen,
  isMeasurementDownloadModalOpen,
  isConfirmationModalOpen,
  isSyncFailedModalOpen,
  isEditArrayIndexModal,
}) => (
  <>
    {isAdminPageRemoveLocationModalOpen && <AdminPageRemoveLocationModal/>}
    {isAdminPageRemoveOrganizationModalOpen && <AdminPageRemoveOrganizationModal/>}
    {isAddDeviceModalOpen && <AddDeviceModal/>}
    {isEditDeviceModalOpen && <EditDeviceModal/>}
    {isAddDataPointModalOpen && <DataPointForm isEditForm={false}/>}
    {isEditDataPointModalOpen && <DataPointForm isEditForm={true}/>}
    {isViewDataPointModalOpen && <ViewDataPointModal/>}
    {isReportRemoveModalOpen && <RemoveReportModalContainer/>}
    {isReportDuplicateModalOpen && <DuplicateReportModalContainer/>}
    {isReportLegendDataPointModalOpen && <LegendDataPointModalContainer/>}
    {isReportsTabAddModalOpen && <AddReportsTabModalContainer/>}
    {isReportsTabEditModalOpen && <EditReportsTabModalContainer/>}
    {isReportsTabRemoveModalOpen && <RemoveReportsTabModalContainer/>}
    {isReportAddModalOpen && <AddReportModalContainer/>}
    {isReportEditModalOpen && <EditReportModalContainer/>}
    {isToolAddModalOpen && <AddToolModalContainer/>}
    {isToolEditModalOpen && <EditToolModalContainer/>}
    {isToolDetailsModalOpen && <ViewToolModalContainer/>}
    {isExperimentAddModalOpen && <AddExperimentModalContainer/>}
    {isExperimentEditModalOpen && <EditExperimentModalContainer/>}
    {isExperimentArchiveModalOpen && <ArchiveExperimentModalContainer/>}
    {isExperimentDownloadModalOpen && <DownloadExperimentModalContainer/>}
    {isExperimentDownloadLinksModalOpen && <DownloadLinksExperimentModal/>}
    {isMeasurementMergeModalOpen && <MergeMeasurementModalContainer/>}
    {isMeasurementArchiveModalOpen && <ArchiveMeasurementModalContainer/>}
    {isMeasurementDownloadModalOpen && <DownloadMeasurementModalContainer/>}
    {isConfirmationModalOpen && <ConfirmationModalContainer/>}
    {isSyncFailedModalOpen && <SyncFailedModalContainer/>}
    {isEditArrayIndexModal && <DataPointForm isEditForm={false}/>}
  </>
);

Modals.propTypes = {
  isAdminPageRemoveLocationModalOpen: PropTypes.bool.isRequired,
  isAdminPageRemoveOrganizationModalOpen: PropTypes.bool.isRequired,
  isAddDeviceModalOpen: PropTypes.bool.isRequired,
  isEditDeviceModalOpen: PropTypes.bool.isRequired,
  isAddDataPointModalOpen: PropTypes.bool.isRequired,
  isEditDataPointModalOpen: PropTypes.bool.isRequired,
  isViewDataPointModalOpen: PropTypes.bool.isRequired,
  isReportRemoveModalOpen: PropTypes.bool.isRequired,
  isReportDuplicateModalOpen: PropTypes.bool.isRequired,
  isReportLegendDataPointModalOpen: PropTypes.bool.isRequired,
  isReportsTabAddModalOpen: PropTypes.bool.isRequired,
  isReportsTabEditModalOpen: PropTypes.bool.isRequired,
  isReportsTabRemoveModalOpen: PropTypes.bool.isRequired,
  isReportAddModalOpen: PropTypes.bool.isRequired,
  isReportEditModalOpen: PropTypes.bool.isRequired,
  isToolAddModalOpen: PropTypes.bool.isRequired,
  isToolEditModalOpen: PropTypes.bool.isRequired,
  isToolDetailsModalOpen: PropTypes.bool.isRequired,
  isExperimentAddModalOpen: PropTypes.bool.isRequired,
  isExperimentEditModalOpen: PropTypes.bool.isRequired,
  isExperimentArchiveModalOpen: PropTypes.bool.isRequired,
  isExperimentDownloadModalOpen: PropTypes.bool.isRequired,
  isExperimentDownloadLinksModalOpen: PropTypes.bool.isRequired,
  isMeasurementMergeModalOpen: PropTypes.bool.isRequired,
  isMeasurementArchiveModalOpen: PropTypes.bool.isRequired,
  isMeasurementDownloadModalOpen: PropTypes.bool.isRequired,
  isConfirmationModalOpen: PropTypes.bool.isRequired,
  isSyncFailedModalOpen: PropTypes.bool.isRequired,
};

export default Modals;
