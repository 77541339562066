import { createAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { collectCommand, publishDevice, stopCommand } from '../../core/api/datatrons';
import notificationMessage from '../../messages/notification.message';
import { NOTIFICATION_ERROR } from '../constants/notification.constants';
import { getDatatron } from '../selectors/datatron.selector';
import { setCallbackCommandId } from './configRepo.callback.receive.module';
import { sendNotification } from './notifications.module';
import { AppReducerMapBuilder } from './util';
import { transformErrorToMessageString } from '../../../../common/utils';

type QuickCommand = 'collect' | 'stop' | 'publish';

export const submitQuickCommand = createAction(
  'quick command submitted',
  (deviceId: string, command: QuickCommand) => ({
    payload: { deviceId, command },
  }),
);

export const quickCommandSuccess = createAction('quick command success', (deviceId: string) => ({
  payload: { deviceId },
}));

export const quickCommandFailure = createAction(
  'quick command failure',
  (deviceId: string, error: string) => ({ payload: { deviceId, error } }),
);

export const reducer = AppReducerMapBuilder.new()
  .add(submitQuickCommand, (state, { deviceId }) => ({
    ...state,
    modals: {
      ...state.modals,
      commandState: {
        ...state.modals.commandState,
        deviceId,
        submitting: true,
      },
    },
  }))
  .add(quickCommandSuccess, (state, { deviceId }) => ({
    ...state,
    modals: {
      ...state.modals,
      commandState: {
        submitting: false,
        success: true,
        deviceId,
      },
    },
  }))
  .add(quickCommandFailure, (state, { deviceId, error }) => ({
    ...state,
    modals: {
      ...state.modals,
      commandState: {
        submitting: false,
        success: false,
        deviceId,
        error,
      },
    },
  }))
  .build();

const quickCommandEndpoint = (command: QuickCommand) => {
  switch (command) {
    case 'collect':
      return collectCommand;
    case 'stop':
      return stopCommand;
    case 'publish':
      return publishDevice;
    default:
      return () => ({
        response: null,
      });
  }
};

export function* quickCommandSaga({ payload: { deviceId, command } }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);

  const endpoint = quickCommandEndpoint(command);

  const { response, error } = yield call(endpoint, datatron.id, deviceId);

  if (response) {
    yield put(setCallbackCommandId(response.commandId));
    yield put(quickCommandSuccess(deviceId));
    yield put(sendNotification(notificationMessage.device_generic_success));
  } else {
    yield put(setCallbackCommandId(-1));
    const errorString = transformErrorToMessageString(error);
    yield put(quickCommandFailure(deviceId, errorString));
    yield put(
      sendNotification(notificationMessage.device_generic_failed, errorString, NOTIFICATION_ERROR),
    );
  }
}

export function* watchQuickCommand() {
  yield takeEvery(submitQuickCommand, quickCommandSaga);
}
