import { formValueSelector } from 'redux-form';
import { selector } from './util';

import * as form from '../constants/datatron.device.form.constants';

export const createFormSelector = () => formValueSelector(form.DATATRON_DEVICE_FORM_NAME);

export const selectIsLoading = selector((state) => !!state.datatron.devices.loading);
export const selectIsLoaded = selector((state) => !!state.datatron.devices.loaded);

export const getDeviceFormFields = (state, additionalKeys = []) =>
  createFormSelector()(state, ...form.DATATRON_DEVICE_PROPS, ...additionalKeys);
