/// <reference path="keycloak.d.ts" />
import { apiRequest } from '../core/api/_tools';

//@ts-ignore
const keycloak = Keycloak('/api/keycloak.json');

function setKeycloakToken(accessToken: string, refreshToken: string) {
  // Better to make sure, since undefined is saved as "undefined" and later read as such
  if (accessToken) {
    window.localStorage.setItem('accessToken', accessToken);
  }
  if (refreshToken) {
    window.localStorage.setItem('refreshToken', refreshToken);
  }
}

export function unsetKeycloakToken() {
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('refreshToken');
}

/**
 * @param token
 * @param refreshToken
 */
/* eslint-disable no-console */
export async function initKeycloak(token, refreshToken) {
  try {
    const authenticated = await keycloak.init({
      checkLoginIframe: false,
      onLoad: 'login-required',
      token,
      refreshToken,
      // scope: ["roles"] // No way to pass options to login in init TODO? do keycloak.login() after keycloak.init()?
    });
    console.log(authenticated ? 'authenticated' : 'not authenticated');
  } catch (e) {
    console.error('failed to initialize due to error: ', e);
    return false;
  }

  setKeycloakToken(keycloak.token ?? '', keycloak.refreshToken ?? '');

  // Refresh
  // TODO why does it also refresh fresh tokens? Does it?
  //console.log(`checking if exp ${new Date(keycloak.tokenParsed.exp * 1000)} is expired`)
  //console.log(`jti before updating ${keycloak.tokenParsed.jti}`)
  //console.log(`jti after updating ${keycloak.tokenParsed.jti}`)
  try {
    await keycloak.updateToken(5);
    setKeycloakToken(keycloak.token ?? '', keycloak.refreshToken ?? '');
  } catch (e) {
    console.error('Failed to refresh the token, or the session has expired, error: ', e);
  }

  // Other RPTs
  // backendTokenManager = new TokenManager(keycloak);
  // await backendTokenManager.init();

  return true;
}
/* eslint-enable no-console */

export async function logout(logoutOptions?: { redirectUri: string }) {
  try {
    // Await completion of the apiRequest to ensure http-only cookies are cleared
    await apiRequest('/api/logoutCookies');
  } catch (error) {
    console.error('Failed to clear cookies', error);
  }

  try {
    await keycloak.logout(logoutOptions);
  } catch (error) {
    console.error('Keycloak logout failed', error);
  }
}

export default keycloak;
