import { Todo } from 'common/types/common';
import {
  COLUMN_IN_PROGRESS,
  COLUMN_PRIORITIZED,
  COLUMN_INBOX,
  ListPosition,
  DestinationColumn,
} from '../../redux/constants/column.type.constants';

// TODO: to be removed
const availableEventMoves = {
  [COLUMN_INBOX]: {
    [COLUMN_PRIORITIZED]: true,
    [COLUMN_IN_PROGRESS]: true,
  },
  [COLUMN_PRIORITIZED]: {
    [COLUMN_IN_PROGRESS]: true,
    [COLUMN_PRIORITIZED]: true,
  },
  [COLUMN_IN_PROGRESS]: {
    [COLUMN_PRIORITIZED]: true,
    [COLUMN_IN_PROGRESS]: true,
  },
};
// TODO: to be removed
export const canMoveEvent = (from, to) => !!availableEventMoves[from][to];

export function addActivityIndicatorBeforeUpdate(
  storeStateActivities: Todo,
  activityId: string,
  listPositions: ListPosition[],
) {
  return storeStateActivities.map((activity) => {
    const found = listPositions.find(({ id }) => id === activity.id);
    if (found && activityId === found.id) {
      return {
        ...activity,
        state: found.state,
        position: found.position,
        indicate: true,
      };
    } else if (found) {
      return {
        ...activity,
        state: found.state,
        position: found.position,
      };
    } else {
      return activity;
    }
  });
}

export function removeActivityIndicatorAfterUpdateOrFail(
  storeStateActivities: Todo,
  activityId: string,
) {
  return storeStateActivities.map((activity) => {
    if (activityId === activity.id) {
      return {
        ...activity,
        indicate: false,
      };
    } else {
      return activity;
    }
  });
}

export function createTempActivityBeforeCreate(
  storeStateEvents: Todo,
  eventId: string,
  columnDestination: DestinationColumn,
  position: number,
) {
  return {
    id: eventId,
    state: columnDestination,
    isArchived: false,
    isPlanned: false,
    resolvedTitle: null,
    resolvedComments: null,
    position,
    indicate: true,
    events: storeStateEvents.filter((event) => event.id === eventId),
    assignee: null,
  };
}
