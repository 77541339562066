import { forEach, last, isEmpty } from 'lodash';

const CONFIG_PREFIX = 'config.';

export const convertErrors = (errors: Record<string, string>): Record<string, string> => {
  const newErrors: Record<string, string> = {};

  forEach(errors, (value: string, key: string) => {
    if (key.substring(0, CONFIG_PREFIX.length) === CONFIG_PREFIX) {
      const subkeys = key.replace(CONFIG_PREFIX, '').split('.');
      const lastSubkey = last(subkeys);
      if (lastSubkey) {
        newErrors[lastSubkey] = value;
      }
    } else {
      newErrors[key] = value;
    }
  });

  return newErrors;
};

interface ErrorWithDetails {
  _details: string;
  [key: string]: any;
}

function isErrorWithDetails(error: any): error is ErrorWithDetails {
  return typeof error === 'object' && error !== null && '_details' in error;
}

export function handleApiError(error: any): string | Record<string, string> {
  if (typeof error === 'string') {
    return error;
  } else if (isErrorWithDetails(error)) {
    const { _details: details, config, 'config.(root)': configRoot, ...rest } = error;
    if (!isEmpty(rest)) {
      return convertErrors(rest);
    }
    return error._details;
  } else if (typeof error === 'object' && error !== null) {
    return convertErrors(error);
  }

  // If it's none of the above, return null or handle as needed
  return 'something went wrong';
}
