import { InventoryItem } from '../../schema/inventory';
import { Todo } from '../../../../common/types/common';

export type InventoryState = {
  locations: InventoryItem[];
  machines: InventoryItem['INVENTORY_MACHINES'];
  datatrons: InventoryItem['INVENTORY_DATATRONS'];
  loading: boolean;
  loaded: boolean;
  error: Todo | null;
  preview: {
    loading: boolean;
    loaded: boolean;
    error: Todo | null;
    itemId: Todo | null;
    itemType: Todo | null;
    item: Todo | null;
  };
};

const machines: InventoryState['machines'] = [
  {
    id: 1,
    name: 'Milling Robot 1',
    status: 'alert',
    serialNumber: '1',
  },
  {
    id: 2,
    name: 'Milling Robot 2',
    status: '',
    serialNumber: '2',
  },
];

const locations: InventoryState['locations'] = [
  {
    id: 'LOCATION1',
    name: 'Location',
    path: ['Berlin', 'Floor #1'],
    type: 'city',
    INVENTORY_MACHINES: machines,
    INVENTORY_DATATRONS: [],
  },
];

export const inventoryInitialState: InventoryState = {
  locations: [],
  machines: [],
  datatrons: [],
  loading: false,
  loaded: false,
  error: '',
  preview: {
    loading: false,
    loaded: false,
    error: null,
    itemId: null,
    itemType: null,
    item: null,
  },
};

export const inventoryTestState: InventoryState = {
  locations,
  machines,
  datatrons: [],
  loading: false,
  loaded: true,
  error: '',
  preview: {
    loading: false,
    loaded: false,
    error: null,
    itemId: null,
    itemType: null,
    item: null,
  },
};
