import { createAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../core/api';
import {
  addActivityIndicatorBeforeUpdate,
  removeActivityIndicatorAfterUpdateOrFail,
} from '../../core/common/maintenance.stream';
import { enforceMinimalDuration } from './helper';
import { ListPosition, DestinationColumn } from '../constants/column.type.constants';

type MoveActionAttr = {
  activityId: string;
  destinationColumn: DestinationColumn;
  listPositions: ListPosition[];
};

type ActionSagaParam = {
  payload: MoveActionAttr;
};

export const moveActivity = createAction(
  'move activity',
  (activityId: string, destinationColumn, listPositions: ListPosition[]) => ({
    payload: {
      activityId,
      destinationColumn,
      listPositions,
    },
  }),
);

export const moveActivityFail = createAction(
  'move activity fail',
  (activityId: string, error: Error) => ({
    payload: {
      activityId,
      error,
    },
  }),
);

export const moveActivitySuccess = createAction('move activity success', (activityId: string) => ({
  payload: {
    activityId,
  },
}));

export const reducer = {
  [moveActivity.type]: (storeState, { activityId, listPositions }: MoveActionAttr) => {
    const activities = addActivityIndicatorBeforeUpdate(
      storeState.maintenance.activities,
      activityId,
      listPositions,
    );

    return {
      ...storeState,
      maintenance: {
        ...storeState.maintenance,
        activities,
      },
    };
  },

  [moveActivitySuccess.type]: (storeState, { activityId }: { activityId: string }) => {
    const activities = removeActivityIndicatorAfterUpdateOrFail(
      storeState.maintenance.activities,
      activityId,
    );

    return {
      ...storeState,
      maintenance: {
        ...storeState.maintenance,
        activities,
      },
    };
  },

  [moveActivityFail.type]: (state, { activityId, error }: { activityId: string; error: Error }) => {
    const activities = removeActivityIndicatorAfterUpdateOrFail(
      state.maintenance.activities,
      activityId,
    );

    return {
      ...state,
      maintenance: {
        ...state.maintenance,
        activities,
        error,
      },
    };
  },
};

/**
 * Saga to handle the movement of an activity in a list.
 *
 * This saga manages executing an API call to update the position of an activity based on provided parameters.
 *
 * @param {ActionSagaParam} param0 - The saga payload object.
 * @param {string} param0.payload.activityId - The unique identifier of the activity being moved.
 * @param {ListPosition} param0.payload.listPositions - New position data needed for moving the activity.
 *
 * Actions Dispatched:
 * - On successful movement: dispatches `moveActivitySuccess` with the activityId of the successfully moved activity.
 * - On failure: dispatches `moveActivityFail` along with the activityId and the specific error encountered.
 */

export function* moveActivitySaga({ payload: { activityId, listPositions } }: ActionSagaParam) {
  const startTime = Date.now();
  const { response, error } = yield call(api.activities.updatePositions, {
    positions: listPositions,
  });
  yield* enforceMinimalDuration(startTime);

  if (response) {
    yield put(moveActivitySuccess(activityId));
  } else {
    yield put(moveActivityFail(activityId, error));
  }
}

export function* watchMoveActivity() {
  yield takeEvery(moveActivity, moveActivitySaga);
}
