import { createAction } from 'redux-act';
import { put, call, select, takeEvery } from 'redux-saga/effects';
import { startSubmit, stopSubmit, touch, getFormValues } from 'redux-form';
import { pick } from 'lodash';

import * as form from '../constants/datatron.device.form.constants';
import { getDeviceFormFields } from '../selectors/datatron.devices.selector';
import { getDatatron, getDeviceTypeById } from '../selectors/datatron.selector';
import { closeModal } from './modals.module';
import { DP_ADD_DEVICE } from '../constants/modals.constants';
import * as api from '../../core/api';
import { initializeDevice } from '../../core/common/datatron';
import { NOTIFICATION_EVENT, NOTIFICATION_ERROR } from '../../core/notifications';
import { sendNotification } from './notifications.module';
import notificationMessages from '../../messages/notification.message';
import { handleApiError } from '../../core/common/errorHandling';
import { applyFormValuesOntoConfig } from '../../core/common/dynamicField';
import { validateDevice } from '../../core/validation/validateFieldsBaseOnSchema';
import { CreateDatatronDeviceSchema } from '../../../../common/validation/datatrons.devices.validation';

export const addDevice = createAction('add datatron device');

export const addDeviceSuccess = createAction('add datatron device - success', (payload) => payload);

export const reducer = {
  [addDeviceSuccess.getType()]: (state, device) => ({
    ...state,
    datatron: {
      ...state.datatron,
      devices: {
        ...state.datatron.devices,
        list: [device, ...state.datatron.devices.list],
      },
    },
  }),
};

export function* touchAllFieldsSaga(additionalKeys = []) {
  yield put(
    touch(form.DATATRON_DEVICE_FORM_NAME, ...form.DATATRON_DEVICE_PROPS, ...additionalKeys),
  );
}

export function* getFields(state, datatron, additionalKeys) {
  const fields = yield call(getDeviceFormFields, state, additionalKeys);

  fields[form.LOCATION_FIELD] = datatron.location.id;
  fields[form.ORGANIZATION_FIELD] = datatron.organization?.id;

  return fields;
}

export const shouldRenderField = (field, formValues) => {
  if (!field._validFor) return true;
  return Object.entries(field._validFor).every(([key, values]) => {
    if (key === field._key) return true;

    const currentValue = formValues[key];
    return Array.isArray(values) ? values.includes(currentValue) : values === currentValue;
  });
};

export function* addDeviceSaga() {
  const state = yield select();

  const datatron = yield call(getDatatron, state);
  const preBody = yield call(getFields, state, datatron, []);
  let body = { ...preBody };

  if (preBody && preBody[form.DEVICE_TYPE_FIELD]) {
    const deviceType = yield call(getDeviceTypeById, state, preBody[form.DEVICE_TYPE_FIELD]);
    const additionalFields =
      deviceType && deviceType.hasDeviceSchema ? deviceType.deviceFields : [];
    const formValues = yield select(getFormValues(form.DATATRON_DEVICE_FORM_NAME));
    const filteredFields = additionalFields.filter((field) => shouldRenderField(field, formValues));
    const additionalKeys = filteredFields.map((item) => item._key);
    body = yield call(getFields, state, datatron, additionalKeys);

    if (additionalFields.length > 0) {
      body.config = yield call(
        applyFormValuesOntoConfig,
        {},
        pick(body, additionalKeys),
        additionalFields,
      );
    }

    yield call(touchAllFieldsSaga, additionalKeys);
  } else {
    yield call(touchAllFieldsSaga);
  }
  yield put(startSubmit(form.DATATRON_DEVICE_FORM_NAME));
  const deviceType = yield call(getDeviceTypeById, state, preBody[form.DEVICE_TYPE_FIELD]);

  if (!deviceType) {
    yield put(
      stopSubmit(form.DATATRON_DEVICE_FORM_NAME, {
        [form.DEVICE_TYPE_FIELD]: 'errors.field.required',
      }),
    );
    return;
  }

  const validationResult = validateDevice(
    CreateDatatronDeviceSchema,
    deviceType.deviceSchema,
    body,
  );
  if (validationResult) {
    yield put(stopSubmit(form.DATATRON_DEVICE_FORM_NAME, validationResult));
    return;
  }

  const { response, error } = yield call(api.datatrons.createDevice, datatron.id, body);

  if (response) {
    yield put(addDeviceSuccess(initializeDevice(response)));
    yield put(stopSubmit(form.DATATRON_DEVICE_FORM_NAME));
    yield put(closeModal(DP_ADD_DEVICE));
    yield put(
      sendNotification(
        notificationMessages.new_device_created_title,
        notificationMessages.new_device_created_description,
        NOTIFICATION_EVENT,
      ),
    );
  } else {
    const formattedError = handleApiError(error);
    yield put(stopSubmit(form.DATATRON_DEVICE_FORM_NAME));
    yield put(closeModal(DP_ADD_DEVICE));
    yield put(
      sendNotification(
        notificationMessages.server_error,
        {
          ...notificationMessages.something_happened,
          values: { error: JSON.stringify(formattedError) },
        },
        NOTIFICATION_ERROR,
      ),
    );
  }
}

export function* watchAddDevice() {
  yield takeEvery(addDevice.getType(), addDeviceSaga);
}
