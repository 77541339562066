import { delay } from 'redux-saga/effects';
import { MINIMAL_DURATION_TO_SHOW_LOADER_INDICATOR } from '../constants/maintenance.constants';
/**
 * Ensures that the minimum processing time for an operation is met.
 * @param {number} startTime - The timestamp when the operation started.
 * @param {number} minimalDuration - The minimum required duration in milliseconds.
 */
export function* enforceMinimalDuration(
  startTime: number,
  minimalDuration: number = MINIMAL_DURATION_TO_SHOW_LOADER_INDICATOR,
): Generator {
  const elapsedTime = Date.now() - startTime;
  if (elapsedTime < minimalDuration) {
    yield delay(minimalDuration - elapsedTime);
  }
}
