import _ from 'lodash';
import config from '../config/public';
import { ZodError, ZodIssue } from 'zod';
import { z } from 'zod';
import { REQUIRED, INVALID, INVALID_UUID, INVALID_IP } from './constants/errors';

export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export const sequelizeToPlain = (object) => {
  if (object && object.get && typeof object.get === 'function') {
    return _.cloneDeep(object.get({ plain: true }));
  }
  return _.cloneDeep(object);
};

export const diffInSec = (from, to) => (to.getTime() - from.getTime()) / 1000;

export const isPreviewEnabled = () => config.features.preview;

export const bytesToMega = (bytes) => {
  const megabytes = (bytes / (1024 * 1024)).toFixed(2);
  return megabytes;
};

const transformZodErrorToMessageString = (zodError: ZodError<any>): string => {
  if (!zodError.issues || zodError.issues.length === 0) {
    return 'Unknown error';
  }

  // Map over each issue and create a readable message for each one
  const messages = zodError.issues.map((issue: ZodIssue) => {
    const path = issue.path.join('.');

    switch (issue.code) {
      case 'invalid_type':
        return `Error in "${path}": expected ${issue.expected} but received ${issue.received}. ${issue.message}`;
      case 'unrecognized_keys':
        return `Unrecognized keys in "${path}": ${issue.message}`;
      case 'too_small':
      case 'too_big':
        return `Error in "${path}": ${issue.message}`;
      default:
        return `Error in "${path}": ${issue.message}`;
    }
  });

  // Join all the messages into a single string
  return messages.join('\n');
};

/**
 * Converts an error object to a readable message string.
 *
 * @param err - The error object to be transformed. It can be a ZodError,
 *              a string, or a generic object.
 * @returns A string representation of the error message. If the error is a
 *          ZodError, it delegates to `transformZodErrorToMessageString`.
 *          If it's a string, it returns the string itself. If it's an object,
 *          it returns the JSON stringified version of the error. If none of
 *          these conditions are met, it returns 'Unknown error'.
 */
export const transformErrorToMessageString = (err: any): string => {
  if (err.name === 'ZodError') {
    return transformZodErrorToMessageString(err);
  }
  if (typeof err === 'string') {
    return err;
  }
  if (typeof err === 'object') {
    return JSON.stringify(err);
  }
  return 'Unknown error';
};
/**
 * Transforms a Zod error object into a Record<string, string> error object where
 * the keys are the paths of the invalid fields and the values are the error messages.
 *
 * @param zodError - The zod error to transform.
 * @returns A Record<string, string> error object.
 */
export function transformZodError(zodError: z.ZodError): Record<string, string> {
  const transformedError: Record<string, string> = {};

  for (const issue of zodError.issues) {
    const path = issue.path.join('.');
    let errorMessage = '';

    switch (issue.message) {
      case 'Required':
        errorMessage = REQUIRED;
        break;
      case 'Invalid':
        errorMessage = INVALID;
        break;
      case 'Expected number, received null':
        errorMessage = INVALID;
        break;
      case 'Invalid ip':
        errorMessage = INVALID_IP;
        break;
      case 'Invalid uuid':
        errorMessage = INVALID_UUID;
        break;
      case "Invalid discriminator value. Expected 'ids_range_input' | 'ids_list_input'":
        errorMessage = REQUIRED;
        break;
      default:
        errorMessage = issue.message;
    }

    transformedError[path] = errorMessage;
  }

  return transformedError;
}
