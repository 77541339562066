import {
  createDataPointSchemaFromJSO,
  createZodSchemaFromJSON,
} from '../../../../common/validation/datapoints/jsonToZod';
import { Todo } from '../../../../common/types/common';
import { transformZodError } from '../../../../common/utils';
import {
  dataPointArrayCommonSchema,
  dataPointCommonSchema,
  dataPointIndexCommonSchema,
} from '../common/dataPoint.constants';
import { PropertiesObjectField } from '../../components/modals/datatronPage/dataPointForm/schema';
import {
  ARRAY_DATAPOINT_FORM_TYPE,
  ARRAY_INDEX_DATAPOINT_FORM_TYPE,
  SIMPLE_DATAPOINT_FORM_TYPE,
} from '../../redux/constants/dataPoint.form.constants';
/**
 * Validates a device object against a main device schema (CreateDatatronDeviceSchema or UpdateDatatronDeviceSchema)
 * and a dynamic device schema which is created from a JSON object (deviceSchema).
 *
 * The function returns null if the device object is valid, otherwise it returns an
 * object with string keys and string values, where each key is a dot-separated path to
 * a field and each value is an error message for that field.
 *
 * The error messages are translated into a format that can be used with the react-intl
 * FormattedMessage component.
 *
 * @param deviceSchema the dynamic device schema as a JSON object
 * @param body the device object to validate
 * @returns an object with error messages, or null if the device object is valid
 */
export const validateDevice = (
  mainZodSchema: Todo,
  deviceSchema: Todo,
  body: Todo,
): Record<string, string> | null => {
  try {
    const mainValidationResult = mainZodSchema.safeParse(body);

    const dynamicZodSchema = createZodSchemaFromJSON(deviceSchema);
    const dynamicValidationResult = dynamicZodSchema.safeParse(body);

    if (!dynamicValidationResult.success || !mainValidationResult.success) {
      const validationError = {
        ...(dynamicValidationResult.success
          ? {}
          : transformZodError(dynamicValidationResult.error)),
        ...(mainValidationResult.success ? {} : transformZodError(mainValidationResult.error)),
      };
      return validationError;
    }
  } catch (error) {
    console.error('Failed to validate data point', error);
  }
  return null;
};

/**
 * Given a formType, returns the schema of common fields for a given data point type.
 * @param {string} formType simple, array, or index
 * @returns {Todo} The schema of common fields
 */
const getCommonFieldsSchema = (formType: string): Todo => {
  switch (formType) {
    case 'simple':
      return dataPointCommonSchema;
    case 'array':
      return dataPointArrayCommonSchema;
    case 'index':
      return dataPointIndexCommonSchema;
    default:
      return {};
  }
};

/**
 * Get the schema of extra fields for a given formType.
 * @param {DatapointSchema} dataPointSchema the schema of the data point
 * @param {string} formType the type of the form (simple, array, or index)
 * @returns {PropertiesObjectField | undefined} the schema of extra fields
 */
const getExtraFieldsSchema = (
  dataPointSchema: Todo,
  formType: string,
): PropertiesObjectField | undefined => {
  switch (formType) {
    case 'simple':
      return dataPointSchema.definitions[SIMPLE_DATAPOINT_FORM_TYPE];
    case 'array':
      return dataPointSchema.definitions[ARRAY_DATAPOINT_FORM_TYPE];
    case 'index':
      return dataPointSchema.definitions[ARRAY_INDEX_DATAPOINT_FORM_TYPE];
    default:
      return undefined;
  }
};

/**
 * Given a data point, returns the form type that it should be represented with.
 * @param {Todo} dataPoint the data point
 * @returns {string} 'simple', 'array', or 'index'
 */
export const getFormTypeFromDataPoint = (dataPoint: Todo): string => {
  if (dataPoint?.config?.arrayIndex && dataPoint?.config?.arrayIndex?.length !== 0) {
    return 'index';
  }
  if (dataPoint?.config?.arrayDimensions) {
    return 'array';
  }
  return 'simple';
};

export const validateDataPoint = (
  dataPointExtraSchema: Todo,
  formType: string,
  body: Todo,
): Record<string, string> | null => {
  const commonFields = getCommonFieldsSchema(formType);
  const configFields = getExtraFieldsSchema(dataPointExtraSchema, formType);

  commonFields.properties.config = configFields;

  const fullSchema = {
    ...commonFields,
    properties: {
      ...commonFields.properties,
      config: configFields ?? {},
    },
  };
  try {
    const zodSchema = createDataPointSchemaFromJSO(fullSchema);
    const validationResult = zodSchema.safeParse(body);
    if (!validationResult.success) {
      return transformZodError(validationResult.error);
    }
  } catch (error) {
    console.error('Failed to validate data point', error);
  }
  return null;
};
